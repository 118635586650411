import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

// Lazy loading components
const Loading = lazy(() => import("./pages/Loading"));
const App = lazy(() => import("./App"));

// Custom theme creation
const theme = createTheme({
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          // height: "40px",
          paddingTop: 0,
          color: "#fff",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff",
          },
          "& .MuiSelect-icon": {
            color: "#fff", // Iconning default rangi
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#fff",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#fff", // Labelning default rangi
          "&.Mui-focused": {
            color: "#fff", // Labelning focus holatidagi rangi
          },
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <AnimatePresence>
        <Suspense fallback={<Loading />}>
          <App />
        </Suspense>
      </AnimatePresence>
    </BrowserRouter>
  </ThemeProvider>
);

reportWebVitals();
